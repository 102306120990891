<template>
  <div class="activeLy" v-if="$route.path == '/activeLy' ">
    <div class="banner">
      <img
        src="@/assets/images/parkBanner.png"
        alt=""
      >
    </div>
    <div class="breadBlock">
      <Breadcrumb
        separator=">"
        class="bread"
      >
        <BreadcrumbItem
          v-for="item in $route.query.source"
          @click.native="routeTo(item.path)"
          class="textColorO"
        >{{item.name}}</BreadcrumbItem>
        <BreadcrumbItem class="textColor">限时活动</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="divtjActive">
        <marquee width="100%" behavior="scroll" direction="left" loop class="tjActive">推荐有礼/一键发圈请参与小程序</marquee>
    </div>
     <div class="sweepActivitiesB">
          <div class="sweepActivitiesBox" v-for="item in RecordPageList" @click="routeActiveClick(item.activity_id)">
            <!-- @click="routeTo('/dzpActivities')" -->
             <div class="sweepActivitiesLeft">
              <img :src="item.head_pic" alt="">
            </div>
            <div class="sweepActivitiesRight">
                <div>{{item.activity_name}}</div>
                <div>{{item.description}}</div>
                <div>{{item.activity_start_time}}-{{item.activity_end_time}}</div>
            </div>
          </div>

          <!-- <div class="sweepActivitiesBox" v-for="item in 2"  @click="showModal">
             <div class="sweepActivitiesLeft">

            </div>
            <div class="sweepActivitiesRight">
                <div>领券活动</div>
                <div>幸运抽奖活动的名称是</div>
                <div>2021.01.28-2021.02.28</div>
            </div>
          </div> -->

     </div>
     <div class="sweepActivitiesC">
       <Row class="mb20">
            <Page
              class="mt20 mr20 fr"
              size="small"
              show-elevator
              show-sizer
              show-total
              :total="total"
              :page-size="size"
              :current.sync="current"
              @on-change="search"
              @on-page-size-change="
                (size) => {
                  (size = size), search();
                }
              "
            />
          </Row>
     </div>
     <div class="ItemBg" v-if="showlq" @click="hideModal"></div>
     <div class="ItemBgBox"  v-if="showlq">
       <img class="ItemBgBoxImg" src="../../../../assets/images/active/gb.png" alt="" @click="hideModal">
        <div class="ItemBgBoxOne">活动标题是什么花是代课教师看电视的哈萨克</div>
        <div class="ItemBgBoxTwo">陵园名称</div>
        <div class="ItemBgBoxThree">
          <div class="ItemBgBoxThreeO">
            <div class="ItemBgBoxThreeOLeft">
              <div>代金券</div>
              <div>限本陵园内商品使用</div>
            </div>
            <div class="ItemBgBoxThreeORight">
              <div>1000元</div>
            </div>
          </div>
          <div class="ItemBgBoxThreeT">
            2021.01.28-2021.02.28
          </div>
        </div>
        <div class="ItemBgBoxFour">立即领取</div>
      </div>
  </div>
  <div v-else>
      <router-view />
    </div>
</template>

<script>
import api from '@/util/api'
import { formatDateTimeT } from '@/util'
import instance from '@/util/instance'
export default {
  name:'activeLy',
  data() {
    return {
      cemeteryId: this.$route.query.cemeteryId || 0,
      companyId: this.$route.query.companyId || 0,
      list:{},
      time:'',
      showlq:false,
      total: 0, //总条数
      size:10,
      current:1,
      RecordPageList:[],
    }
  },
  mounted() {
    this.search();
  },
  methods: {
    search(){
      instance.activityBasicPageAll({
        current:this.current,
        size:this.size,
        type:2,
        company_id:this.companyId,
        activity_type:100,
        // orders:this.orders,
      },res=>{
        console.log("res",res);
          this.RecordPageList = res.data.data.records || [];
          this.total = Number(res.data.data.total) || 0;
          this.size = Number(res.data.data.size) || 0;
          this.current = Number(res.data.data.current) || 0;
      })
    },
    showModal(){
      this.showlq =true;
    },
    hideModal(){
      this.showlq =false;
    },
    routeActiveClick(id){
       this.$router.push({ path: '/dzpActivities', query: {'dzpId':id} });
    },
  },
}
</script>

<style lang="less" scoped>
.activeLy {
  .banner {
    height: 220px;
  }
  .breadBlock {
    line-height: 40px;
    cursor: pointer;

    .bread {
      width: 1120px;
      margin: auto;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      letter-spacing: 1px;
      .textColor {
        font-weight: 400;
        color: #299654;
      }
    }
    /deep/.ivu-breadcrumb-item-separator {
      color: #929292;
    }
  }
  .sweepActivitiesC{
    width:1120px;
    margin:auto;
  }
  .main {
    width: 1120px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 24px;
    img {
      width: 1072px;
      height: 431px;
      border-radius: 4px;
    }
    .content {
      margin-top: 22px;
      overflow: hidden;

      .desc {
        display: flex;
        margin-bottom: 40px;
      }
      .item {
        float: left;
        width: 525px;
        display: flex;
        margin-bottom: 16px;
        overflow: hidden;
        margin-right: 20px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      .label {
        width: 78px;
        height: 24px;
        font-size: 18px;
        font-weight: 500;
        color: #4d4d4d;
        line-height: 24px;
        letter-spacing: 1px;
        margin-right: 16px;
        font-weight: 600;
      }

      p {
        flex: 1;
        font-size: 18px;
        font-weight: 400;
        color: #4d4d4d;
        line-height: 27px;
        letter-spacing: 1px;
        text-align: justify;
      }
    }
  }
  .sweepActivitiesB{
        width:1120px;
        margin:auto;
        display: flex;
        flex-wrap: wrap;
        margin-bottom:20px;
      }
      .sweepActivitiesBox{
        display: flex;
        flex-wrap: nowrap;
        margin-top:20px;
        cursor: pointer;
      }
      .sweepActivitiesBox:not(:nth-child(2n+1)){
        margin-left:180px;
      }
      .sweepActivitiesLeft{
        width:243px;
        height:120px;
        // background-color: red;
        background-color: white;
      }
      .sweepActivitiesRight{
        width:210px;
        height:120px;
        margin-left:10px;
      }
      .sweepActivitiesRight>div:nth-child(1){
        // width: 59px;
        height: 21px;
        line-height: 21px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #F8B60C;
        color:#F8B60C;
        display: inline;
      }
      .sweepActivitiesRight>div:nth-child(2){
        height:46px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 22px;
        letter-spacing: 1px;
        margin-top:10px;
      }
      .sweepActivitiesRight>div:nth-child(3){
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #299654;
        line-height: 17px;
      }

  .ItemBg{
    width:100%;
    height:100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(55,55,55,.6);
    z-index: 1000;
  }
    .ItemBgBox{
      width:360px;
      height:374px;
      background-image:url(../../../../assets/images/active/lq.png);
      background-repeat: no-repeat;
      position: fixed;
      top: 35%;
      left: 40%;
      z-index: 1001;
  }
  .ItemBgBoxImg{
      width:40px;
      height:40px;
      position: absolute;
      right:10px;
      top:10px;
      cursor: pointer;
      z-index: 1002;
  }
  .ItemBgBoxOne{
    width: 288px;
    height: 66px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FEE886;
    line-height: 33px;
    text-align: center;
    margin:50px auto 0;
  }
  .ItemBgBoxTwo{
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
    text-align: center;
    opacity:0.6;
    margin-top:8px;
  }
  .ItemBgBoxThree{
    // display: flex;
    // justify-content: space-between;
    width: 267px;
    height: 118px;
    margin-left: 45px;
    margin-top: 14px;
  }
  .ItemBgBoxThreeO{
    width:100%;
    height:80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ItemBgBoxThreeOLeft{
    width:60%;
  }
  .ItemBgBoxThreeOLeft>div:nth-child(1){
    height: 21px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #E64747;
    line-height: 21px;
    padding-left:20px;
  }
  .ItemBgBoxThreeOLeft>div:nth-child(2){
    height: 18px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #626262;
    line-height: 18px;
    padding-left:20px;
    margin-top:6px;
  }
  .ItemBgBoxThreeORight{
    width:40%;
    text-align: center;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #E64747;

  }
  .ItemBgBoxThreeT{
    width:100%;
    height:38px;
    line-height:38px;
    padding-left:20px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #969696;
  }
  .ItemBgBoxFour{
    width: 214px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    background: linear-gradient(270deg, #FFC23E 0%, #F4D03B 100%);
    box-shadow: 0px 3px 7px 0px rgba(231, 29, 47, 0.49);
    border-radius: 23px;
    margin:auto;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
    position: absolute;
    bottom:30px;
    left:20%;
  }
}
.divtjActive{
  width:1120px;
  margin:auto;
}
.tjActive{
  font-size:12px;
  color:red;
  overflow:-webkit-marquee;
  -webkit-marquee-direcTIon:left;
  -webkit-marquee-speed:normal;
  -webkit-marquee-style:scroll;
  -webkit-marquee-repeTITIon:infinite;
}
</style>